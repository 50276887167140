import React from "react"
import { Box } from "@chakra-ui/core"
import { SectionHeading, Paragraph, Bold } from "../components/ui-elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link as GatsbyLink} from "gatsby";
import VirtualAssistant from "./virtual-assistant"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Us - VERIFiSMART" description="Get to Know VerifiSmart - Your Trusted Partner in Copyright Protection. Created by UK and EU IP law experts. Discover our mission, values, and the team behind our innovative copyright protection solutions. Learn why businesses choose us for secure, efficient, and customer-centric identity copyright ownership verification services. Protect now your creations and ideas with VERIFiSMART."/>
      <Box color="theme-dark" py="16" id="about">
        <Box px="4" w="100%" maxW="containers.xl" mx="auto">
          <SectionHeading>About Us</SectionHeading>
          <Paragraph>
            VERIFiSMART is a legal solution platform that provides and allows
            individuals and companies alike to protect their creations, ideas
            and innovations by keeping encrypted records of their projects to
            prevent leaks or unauthorised access to them while the work is being
            developed and to register the final versions that will be released
            to the public for commercial purposes by creating a unique hash and
            digital timestamp as prove of the exact moment on which the work was
            created by such individual or company.
          </Paragraph>

          <Paragraph>
            VERIFiSMART uses blockchain technology at its core to ensure
            security, permanency and validity of such registrations as the
            nature of this technology relies on being{" "}
            <Bold>decentralised, unalterable, timestamped and auditable</Bold> –
            therefore, transparent, unchallengeable and reliable.
          </Paragraph>

          <Paragraph>
            VERIFiSMART was created by lawyers specialized in intellectual
            property law in the UK and EU after noticing that clients would
            often struggle with the same issue: having to go through old files
            and records to prove the moment on which they created a work that
            was infringed by others having to incur in significant time and
            money (including legal fees) to be able to prove ownership, without
            certainty that such time and costs could ever be recovered in the
            event of a successful claim.
          </Paragraph>

          <Paragraph>
            While some experienced clients would have found out that they could
            prevented that issue if they take the appropriate steps to keep
            accurate records of their creations, often all those methods posed
            significant risks of confidentiality or involve additional costs as
            expenses by engaging attorneys or using various different services
            that would still not offer 100% confidentiality and reliability.
          </Paragraph>

          <Paragraph>
            Thus, VERIFiSMART was born! The unique features offered by
            VERIFiSMART are curated from knowledge and experience on the legal
            risks and challenges that can arise when trying to protect what are
            otherwise unregistrable intellectual property rights. VERIFiSMART
            aims to facilitate the protection of such rights in a simple,
            secured and cost-effective way, by removing the need to pay for
            legal fees until they are really needed.
          </Paragraph>
          <Paragraph>
            At VERIFiSMART, we aim to provide a cost-effective solution.
            Check out our{" "}
            <Bold
              textDecoration="underline"
              to="/pricing"
              as={GatsbyLink}
          >Pricing
          </Bold> page for more information on our pricing plans.
          </Paragraph>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default AboutUs
